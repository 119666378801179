.custom-calendar .ant-picker-cell-inner {
    height: 60px;
    padding: 4px;
}

.custom-calendar .ant-picker-panel .ant-picker-content th,
.custom-calendar .ant-picker-panel .ant-picker-content td {
    font-size: 14px;
    padding: 8px;
}

.custom-calendar {
    font-family: Arial, sans-serif;
}

.events {
    list-style: none;
    padding-left: 0;
    margin-bottom: 0;
    overflow-y: auto;
    max-height: 150px;
    scrollbar-width: thin;
    scrollbar-color: #A0AEC0 transparent;
    padding: 4px;
    background-color: #f4f4f4;
    border-radius: 4px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}

/* Scrollbar personnalisé pour rendre plus discrète et moderne */
.events {
    overflow-y: auto;
    max-height: 150px; /* Ajustez la hauteur maximale si nécessaire */
    scrollbar-width: thin; /* Pour rendre la scrollbar plus fine */
    scrollbar-color: #A0AEC0 transparent; /* Couleur de la scrollbar et du fond */
}

/* Scrollbar pour les navigateurs basés sur Webkit (Chrome, Safari, etc.) */
.events::-webkit-scrollbar {
    width: 6px; /* Réduit la largeur de la scrollbar */
}

.events::-webkit-scrollbar-track {
    background: transparent; /* Fond transparent pour une intégration discrète */
}

.events::-webkit-scrollbar-thumb {
    background-color: #A0AEC0; /* Couleur de la barre de défilement */
    border-radius: 10px; /* Barre arrondie pour un style moderne */
    border: 2px solid transparent; /* Espace autour pour un effet flottant */
}

.events::-webkit-scrollbar-thumb:hover {
    background-color: #718096; /* Couleur légèrement différente au survol pour un retour visuel */
}

.event-item:last-child {
    margin-bottom: 0;
}

.event-item:hover {
    background-color: #e6f7ff;
}

.event-title {
    font-size: 12px;
    font-weight: bold;
    color: #333;
    flex-grow: 1;
    margin-right: 10px;
}

.tooltip-content {
    font-size: 12px;
    color: #888;
}

.custom-calendar .ant-picker-panel .ant-picker-content th,
.custom-calendar .ant-picker-panel .ant-picker-content td {
    font-size: 16px;
    padding: 12px;
}

.custom-calendar .ant-picker-content td {
    height: 90px;
}


@media (max-width: 768px) {
    .custom-calendar .ant-picker-cell-inner {
        height: 45px;
        padding: 2px;
    }

    .custom-calendar .ant-picker-panel .ant-picker-content th,
    .custom-calendar .ant-picker-panel .ant-picker-content td {
        font-size: 12px;
        padding: 8px;
    }

    .events {
        padding: 2px;
    }

    .event-title {
        font-size: 10px;
        margin-right: 5px;
    }

    .tooltip-content {
        font-size: 10px;
    }

    .custom-calendar .ant-picker-content td {
        height: 70px;
    }
}

@media (max-width: 480px) {
    .custom-calendar .ant-picker-cell-inner {
        height: 35px;
        padding: 1px;
    }

    .custom-calendar .ant-picker-panel .ant-picker-content th,
    .custom-calendar .ant-picker-panel .ant-picker-content td {
        font-size: 10px;
        padding: 4px;
    }



    .events {
        padding: 1px;
    }

    .event-title {
        font-size: 9px;
        margin-right: 3px;
    }

    .tooltip-content {
        font-size: 9px;
    }

    .custom-calendar .ant-picker-content td {
        height: 60px;
    }
}
