.custom-icon svg {
    transition: transform 0.2s ease;
}

.custom-icon:hover svg {
    transform: scale(1.5);
}

.popup-completed {
    border: 2px solid green;
    text-align: center;
    border-radius: 12px;

}

.popup-in-progress {
    border: 2px solid orange;
    text-align: center;
    border-radius: 12px;
}

.popup-late {
    border: 2px solid red;
    text-align: center;
    border-radius: 12px;
}
