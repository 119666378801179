.custom-scrollbar {
  scrollbar-width: thin;
  scrollbar-color: transparent transparent;
  border-radius: 8px;

  &::-webkit-scrollbar {
    width: 12px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: transparent;
    border-radius: 8px;
  }

  &::-webkit-scrollbar-track {
    background-color: transparent;
  }

  &:hover {
    scrollbar-color: #b6d8f2 #ffffff;
  }

  &:hover::-webkit-scrollbar-thumb {
    background-color: #b6d8f2;
  }
}
